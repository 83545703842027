<template>
    <div>
        <header class="relative w-full min-h-dvh">
            <div class="absolute inset-0">
                <video ref="backgroundVideo" autoplay muted loop playsinline
                       class="z-10 w-full h-full object-cover"
                       poster="/medias/previews/dog_on_black.webp"
                >
                    <template v-for="format in formats" :key="format">
                        <source v-for="resolution in resolutions" :key="resolution.size"
                                :src="`${media}-${resolution.size}.${format}`"
                                :type="`video/${format}`"
                                :media="resolution.media">
                    </template>
                    {{alertmessage}}
                </video>
            </div>
        </header>
        <!-- logo -->
        <div
            v-if="show_logo"
            data-aos="slide-right"
            class="absolute left-0 top-20 w-full z-30"
        >
            <div class="w-full relative flex flex-col items-center lg:flex-row lg:items-start
                        lg:justify-start lg:text-left lg:ml-8"
            >
                <!-- logo -->
                <div class="w-20 lg:w-48 mx-auto mt-6 lg:mr-4 lg:ml-0 lg:mt-4 lg:mx-0'">
                    <ImgLoading
                        :AttributeImg="{
                                    source:'/images/logo/logo.webp',
                                    altText:$t('logo.alt'),
                                    titleText:$t('logo.title'),
                                    className:'w-auto mx-auto',
                                    srcset: `/images/logo/logo-sm.webp 480w,
                                            /images/logo/logo-md.webp 800w,
                                            /images/logo/logo-lg.webp 1200w,
                                            /images/logo/logo-xl.webp 2400w,
                                            /images/logo/logo.webp 3600w`,
                                    sizes: `(max-width: 480px) 100vw,
                                        (max-width: 800px) 50vw,
                                        (max-width: 1200px) 33vw,
                                        (max-width: 2400px) 25vw,
                                        (max-width: 3200px) 20vw,
                                        16vw`,
                                    width: '3600',  // Dimensions de l'image la plus grande
                                    height: '3600'
                                }"
                    />
                </div>
                <!-- title -->
                <div class="text-center lg:text-left">
                    <p class="w-full text-7xl lg:text-10xl font-bold text-center md:pt-4
                                bg-gradient-to-r from-lime-400 via-pink-500 to-fuchsia-500 bg-clip-text
                                text-transparent font-wildyouth"
                    >
                        DOG
                    </p>
                    <p class="w-full text-2xl lg:text-4xl font-bold text-center
                                       bg-gradient-to-r from-lime-400 via-pink-500 to-fuchsia-500 bg-clip-text
                                       text-transparent"
                    >
                        Dream Optical Glasses
                    </p>
                </div>
            </div>
        </div>
        <!-- compatible mobile             -->
        <div
            v-if="show_logo"
            class="absolute left-2 lg:left-4 bottom-32 lg:bottom-32 z-40 mx-auto space-y-3 text-white"
        >
            <p class="ml-2 text-xs md:text-base">{{$t('iconSpec.compatible.title')}}</p>
            <div class="w-full flex justify-between gap-3">
                <div class="text-sm text-center">
                    <ImgLoading
                        :AttributeImg="{
                                    source:'/images/icons/icon-apple-white.svg',
                                    altText:$t('iconSpec.compatible.appleAlt'),
                                    titleText:$t('iconSpec.compatible.appleTitle'),
                                    className:brandicon,
                                    width: '60',
                                    height: '60'
                                }"
                    />
                    <p>Apple</p>
                </div>
                <div class="text-sm text-center">
                    <ImgLoading
                        :AttributeImg="{
                                    source:'/images/icons/icon-android-white.svg',
                                    altText:$t('iconSpec.compatible.androidAlt'),
                                    titleText:$t('iconSpec.compatible.androidTitle'),
                                    className:brandicon,
                                    width: '60',
                                    height: '60'
                                }"
                    />
                    <p>Android</p>
                </div>
                <div class="text-sm text-center">
                    <ImgLoading
                        :AttributeImg="{
                                    source:'/images/icons/icon-microsoft-white.svg',
                                    altText:$t('iconSpec.compatible.microsoftAlt'),
                                    titleText:$t('iconSpec.compatible.microsoftTitle'),
                                    className:brandicon,
                                    width: '60',
                                    height: '60'
                                }"
                    />
                    <p>Microsoft</p>
                </div>
            </div>
        </div>
        <!-- text -->
        <div class="absolute left-0 bottom-14 lg:bottom-12 z-30">
            <div
                v-if="show_text"
                class="relative p-2 lg:p-4 bg-gray-700 opacity-90
                   font-bold text-sm lg:text-2xl rounded-r-full
                   "
            >
                <transition name="fade">
                    <p class="w-full text-left xl:pr-8 custom-text-gradient">
                        {{ textTitle }}
                    </p>
                </transition>
            </div>
        </div>
        <!-- volume -->
        <div class="absolute right-6 bottom-1 lg:bottom-3 opacity-60 z-30">
            <div
                class="w-full relative z-10 p-2 text-white rounded-full
                    font-bold text-lg lg:text-xl text-right bg-gray-700
                    "
            >
                <font-awesome-icon
                    @click="handleToogleSound"
                    v-if="muted"
                    :icon="faVolumeMute"
                    class="cursor-pointer"
                />
                <font-awesome-icon
                    @click="handleToogleSound"
                    v-else
                    :icon="faVolumeHigh"
                    class="cursor-pointer"
                />
            </div>
        </div>
    </div>
</template>

<script setup>

    import {faVolumeHigh, faVolumeMute} from "@fortawesome/free-solid-svg-icons";
    import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
    import ImgLoading from "@/Components/ImgLoading.vue";
    import {onMounted, ref} from "vue";

    const props = defineProps({
        media:{type:String, required: true},
        alertmessage:{type:String, required: true},
        textTitle1:{type:String, required: true},
        textTitle2:{type:String, required: true},
        textTitle3:{type:String, required: true},
    });
    const brandicon = "h-5 lg:h-8 mx-auto"
    const muted = ref(true);
    const backgroundVideo = ref(null);
    const show_logo = ref(true);
    const show_text = ref(false);
    const show_text1 = ref(false);
    const show_text2 = ref(false);
    const show_text3 = ref(false);
    const show_all = ref(true);
    const textTitle = ref('');
    const formats = ['webm','mp4']
    const resolutions = [
        { size: '240p', media: '(max-width: 240px)' },
        { size: '360p', media: '(max-width: 360px)' },
        { size: '480p', media: '(max-width: 480px)' },
        { size: '720p', media: '(max-width: 768px)' }, // Large phones and tablets in portrait
        { size: '1080p', media: '(min-width: 769px)'}, // Default for larger screens
    ];

    function handleToogleSound(){
        muted.value = !muted.value;
        if (backgroundVideo.value) {
            backgroundVideo.value.muted = muted.value;
        }
    }

    onMounted(() => {
        const video = document.querySelector('video');
        video.addEventListener('timeupdate', () => {

            const currentTime = video.currentTime;

            if (currentTime > 0 && currentTime <= 13) {
                show_logo.value = true;
            }else{
                show_logo.value = false;
            }

            if (currentTime >0 && currentTime <= 4) {
                textTitle.value=props.textTitle1;
                show_text.value=true;
            }

            if (currentTime >4 && currentTime <= 8) {
                textTitle.value=props.textTitle2;
            }

            if (currentTime >8 && currentTime <= 13) {
                textTitle.value=props.textTitle3;
            }
            if (currentTime >12 && currentTime <= 13) {
                textTitle.value='';
                show_text.value=false;
            }

        });
    });
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity 1s;
}
.fade-enter-from, .fade-leave-to {
    opacity: 0;
}
</style>